/*
 * Abraham Oliver
 * Cloudflare General Application
 * October 2021
 */

import React from 'react';

// Material Design System
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, blue, purple, green, orange, pink } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import './App.css';

const URL_BASE = 'https://workers.xabe.workers.dev';

export default function App() {
  return (
    <div className="App">
      <header className="App-header">
        <MainView/>
      </header>
    </div>
  );
};

/* ------------------------------------
            COMPONENTS
-------------------------------------*/

class MainView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {posts: [], name: "", content: ""};
  }

  componentDidMount() {
    this.updatePosts();
  }

  updatePosts() {
    // Send GET request to backend
    const xhr = new XMLHttpRequest();
    xhr.open('GET', URL_BASE + "/posts");
    xhr.onload = () => {
      // Load posts into state
      this.setState({
        posts: JSON.parse(xhr.response).posts
      });
    }
    xhr.send();
  }

  post() {
    // Send a POST to the backend with the new post
    const xhr = new XMLHttpRequest();
    xhr.open('POST', URL_BASE + "/posts");
    xhr.send(JSON.stringify({
      "user": this.state.name,
      "content": this.state.content,
      "date": getDate()
    }));
    // Reset the new-post textbox and update post list
    xhr.onload = () => {
      this.setState({name: "", content: ""});
      this.updatePosts();
    };

  }

  like(id) {
    // Send a POST to the backend with id of post to like
    const xhr = new XMLHttpRequest();
    xhr.open('POST', URL_BASE + "/like");
    xhr.send(JSON.stringify({"id": id}));
    // Update post list
    xhr.onload = () => {
      this.updatePosts();
    };
  }

  render() {
    return (
      <div>
        <Typography variant="h1" component="p">
          <b>ThoughtPost</b>
        </Typography>
        <Typography variant="h5" component="p">
          Built for Cloudflare by Abraham Oliver
        </Typography>
        <br/>
        <Grid container spacing={100} sx={{"padding-left": "1em"}}>
          <Grid container item xs={6}>
            <div>
              <Grid container item spacing={2} columns={3}
                    direction="row" alignItems="center"
                    sx={{"width": "75vw"}}>
                {this.displayPosts()}
              </Grid>
            </div>
          </Grid>
          <Grid container item xs={2}>
            <div>
            {this.interactiveCard()}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  displayPosts() {
    let post_array = [];
    let k = 0;
    for (let post of this.state.posts) {
      post_array.push((
        <Grid item xs={1} key={k}>
          {this.postCard(post.id || k,
                         post.content || "<No Contents>",
                         post.user || "Hidden User",
                         post.date || defaultDate,
                         post.likes || 0)}
        </Grid>
      ));
      k++;
    }
    return post_array;
  }

  interactiveCard() {
    return (
      <Card sx={{ "width": "20vw", "height": "70vh" }}>
        <CardHeader title = "NEW THOUGHT"/>
        <CardContent>
          <TextField
            id="post-name" label="Name"
            sx={{"width": "100%"}}
            value={this.state.name}
            onChange={(e) => {
              this.setState({name: e.target.value})
            }}
          />
          <div style={{"padding-top": "1em", "padding-bottom": "1em"}}>
          <TextField multiline rows={12}
            id="post-content" label="New Post"
            sx={{"width": "100%"}}
            value={this.state.content}
            onChange={(e) => {
              this.setState({content: e.target.value})
            }}
          />
          </div>
          <Button variant="contained"
                  onClick={() => this.post()}
                  sx={{"width": "100%"}}>POST</Button>
        </CardContent>
      </Card>
    );
  }

  postCard(id, content, username, date, likes) {
    // https://mui.com/components/cards/
    // Choose avatar based on username length
    const avatar_colors = [red[500], blue[500], purple[500], orange[500], green[500], pink[500]];
    const av_color = avatar_colors[username.length % avatar_colors.length];
    return (
      <Card sx={{ "width": "20vw", "height": "24vh" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor:  av_color}} aria-label="user">
              {username.toUpperCase()[0]}
            </Avatar>
          }
          title={username}
          subheader={parseDate(date)}
          style={{"padding-bottom": "10px"}}
        />
        <CardContent style={{"padding-bottom": "0px"}}>
          <div style={{"overflow": "scroll", "white-space": "wrap", "height": "2em"}}>
            <Typography variant="body2" color="text.secondary">
              {content}
            </Typography>
          </div>
        </CardContent>
        <CardActions>
          <IconButton aria-label="like" onClick={() => this.like(id)}>
            <FavoriteIcon />
          </IconButton>
          <Typography variant="body2" color="text.secondary">
            {likes}
          </Typography>
        </CardActions>
      </Card>
    );
  }
}

/* ------------------------------------
          UTILITY FUNCTIONS
-------------------------------------*/
function getDate() {
  let raw_d = new Date();
  return {
    y: raw_d.getFullYear(),
    d: raw_d.getDate(),
    mo: raw_d.getMonth(),
    h: raw_d.getHours(),
    mi: raw_d.getMinutes()
  };

}

function parseDate(d) {
  // Parse hour
  let ap = "AM";
  let hr = d.h;
  if (d.h >= 12) {
    hr = d.h % 12;
    ap = "PM"
  }
  if (hr == 0) hr = 12;

  // Parse minute
  let min = d.mi.toString();
  if (min == "0") min = "00";
  if (min.lenth == 1) min = "0" + min;


  // Construct date string
  return hr + ":" + min + " " + ap +
          " " + d.mo + "/" + d.d + "/" + d.y;
}

const defaultDate = {
  y: 2000, d: 1, mo: 1, h: 12, mi: 0
};
